declare global {
  interface Window {
    onTurnstileSuccess: () => void
  }
}

$(function () {
  $('#contact-form').on('submit', function (e) {
    e.preventDefault()
    $('#contact-form-submit-button').prop('disabled', true)
    const $form = $(this)
    const data = $form.serialize()
    // @ts-expect-error axios is a global variable
    axios
      .post('/api/contact', data)
      // .then(function (response) {
      .then(function () {
        // TODO: Give success feedback
        turnstile.reset()
        $('#contact-form-submit-button').prop('disabled', false)
        $('.form-error').hide()
        $('.form-success').show()
        $form.hide()
      })
      // .catch(function (error) {
      .catch(function () {
        // TODO: Give error feedback
        turnstile.reset()
        $('#contact-form-submit-button').prop('disabled', false)
        $('.form-success').hide()
        $('.form-error').show()
      })
  })

  if ($('.venobox').length) {
    // @ts-expect-error VenoBox is a global variable
    new VenoBox({
      selector: '.venobox',
      numeration: true,
      infinigall: true,
      spinner: 'rotating-plane',
    })
  }

  // General Image Component loading
  // When the image is loaded, add the loaded class
  $('.blur-load img').on('load', function () {
    // console.log('Loaded', $(this).attr('src'))
    $(this).closest('.blur-load').addClass('loaded')
  })

  // If the image is already loaded, add the loaded class
  $('.blur-load img').each(function () {
    // console.log('this.complete', this.complete)
    // @ts-expect-error complete is a property of the image
    if (this.complete) {
      // console.log('Complete', $(this).attr('src'))
      $(this).closest('.blur-load').addClass('loaded')
    }
  })

  // Hero block image loading
  $('.block-hero').each(function () {
    const heroBlock = $(this)
    const url = heroBlock.data('src')
    const img = new Image()
    img.src = url
    $(img).on('load', function () {
      heroBlock.find('.hero-blur-load').fadeOut(1000)
    })
  })
})
// function onTurnstileSuccess(response) {
window.onTurnstileSuccess = function onTurnstileSuccess() {
  $('#contact-form-submit-button').prop('disabled', false)
}
